import React, { useEffect } from "react";
import "./AllTataEvCars.css";
import "../../../Common-CSS/BlogPost.css";
import img1 from "../../../Images/Tata/avinya.jpg"
import nexon from "../../../Images/Tata/nexonEv.jpeg";
import tigor from "../../../Images/Tata/Tigor.png";
import punch from "../../../Images/Tata/punch.png";
import tiago from "../../../Images/Tata/tiago.png";
import ReactGA from 'react-ga';



function AllTataEvCars() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="blogPost-main">
      <div className="blogPost-content">
        <div className="blogPost-content-heading">
          <title>Tata Electric cars</title>
          <h1>Tata Electric cars | Tata motors | Tata Ev</h1>
          {/* <p>
            Introduction to important principles you should know — DRY, KISS,
            SOLID
          </p> */}
        </div>
        <div className="blogPost-content-mainContent">
          <p className="firstPara">
            Dive into the world of Tata Motors' innovative electric vehicles
            (EVs) that are revolutionizing the automotive industry. Discover a
            range of eco-friendly and technologically advanced cars designed to
            offer superior performance, comfort, and sustainability. From the
            stylish and efficient Tata Nexon EV to the versatile and dynamic
            Tata Tigor EV, explore the features, specifications, and benefits of
            each model. Stay updated on the latest advancements, charging
            infrastructure, and government incentives that make owning a Tata EV
            an exciting and viable option for the environmentally conscious
            driver.
          </p>
          <img className="blogPost-content-mainContent-image" src={img1} />

          {/* Card 1 - Nexon */}
          <h3 className="subHeading subHeading1 tataSubheading">Tata Nexon</h3>
          <div className="tata-blogCard">
            <div className="tata-blogCard-card">
              <div className="tata-blogCard-card-top">
                <img className="tata-blogCard-card-top-img" src={nexon} />
                <h1>Nexon Ev</h1>
              </div>
              <div className="tata-blogCard-card-bottom">
                <div className="tata-blogCard-card-bottom-left">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Power Output
                    </p>
                    <p>106.4 kW</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">Torque</p>
                    <p>215NM</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Acceleration (0-100 kmph)
                    </p>
                    <p> 8.9^s</p>
                  </div>
                </div>
                <div className="tata-blogCard-card-bottom-right">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p>
                      Starting at <span>₹ 14.49 Lakh*</span>
                    </p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Range</p>
                    <p>465# km</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Estimated DC Fast Charging Time</p>
                    <p>56* minutes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 2 - Tigor */}
          <h3 className="subHeading subHeading1 tataSubheading">Tata Tigor</h3>
          <div className="tata-blogCard">
            <div className="tata-blogCard-card">
              <div className="tata-blogCard-card-top">
                <img className="tata-blogCard-card-top-img tigorImg" src={tigor} />
                <h1>Tigor Ev</h1>
              </div>
              <div className="tata-blogCard-card-bottom">
                <div className="tata-blogCard-card-bottom-left">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Power Output
                    </p>
                    <p>95 kW</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">Torque</p>
                    <p>215 Nm</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Acceleration (0-60 kmph)
                    </p>
                    <p> 5.7^s</p>
                  </div>
                </div>
                <div className="tata-blogCard-card-bottom-right">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p>
                      Starting at <span>₹12.49 Lakh*</span>
                    </p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Range</p>
                    <p>325# km</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Estimated DC Fast Charging Time</p>
                    <p>.SOC 10% to 80% in 56▲ minutes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 3 - Punch */}
          <h3 className="subHeading subHeading1 tataSubheading">Tata Punch</h3>
          <div className="tata-blogCard">
            <div className="tata-blogCard-card">
              <div className="tata-blogCard-card-top">
                <img className="tata-blogCard-card-top-img punchImg" src={punch}/>
                <h1>Punch Ev</h1>
              </div>
              <div className="tata-blogCard-card-bottom">
                <div className="tata-blogCard-card-bottom-left">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Power Output
                    </p>
                    <p>90 kW </p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">Torque</p>
                    <p>190 Nm</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Acceleration (0-100 kmph)
                    </p>
                    <p> 9.5^s</p>
                  </div>
                </div>
                <div className="tata-blogCard-card-bottom-right">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p>
                      Starting at <span>₹ 10.99 Lakh*</span>
                    </p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Range</p>
                    <p>315# km</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Estimated DC Fast Charging Time</p>
                    <p>. SOC 10% - 80% in 56▲ minutes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 4 - Tiago */}
          <h3 className="subHeading subHeading1 tataSubheading">Tata Tiago</h3>
          <div className="tata-blogCard">
            <div className="tata-blogCard-card">
              <div className="tata-blogCard-card-top">
                <img className="tata-blogCard-card-top-img tiagoImg"  src={tiago}/>
                <h1>Tiago Ev</h1>
              </div>
              <div className="tata-blogCard-card-bottom">
                <div className="tata-blogCard-card-bottom-left">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Power Output
                    </p>
                    <p>55 kW</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">Torque</p>
                    <p>114 Nm</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p className="tata-blogCard-card-bottom-left-p">
                      Acceleration (0-60 kmph)
                    </p>
                    <p> 6.2^s</p>
                  </div>
                </div>
                <div className="tata-blogCard-card-bottom-right">
                  <div className="tata-blogCard-card-bottom-left-div">
                    <p>
                      Starting at <span>₹7.99 Lakh*</span>
                    </p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Range</p>
                    <p>250# km</p>
                  </div>
                  <div className="tata-blogCard-card-bottom-left-div tata-blogCard-card-bottom-right-variation">
                    <p>Estimated DC Fast Charging Time</p>
                    <p>.SOC 10% to 80% in 58▲ minutes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTataEvCars;
