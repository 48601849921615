import React from "react";
import "./Blog.css";
import img1 from '../../Images/blogPost01.png'

function Blog({data}) {
    console.log(data)
  return (
    <div className="blog-main">
      <div className="blog-content">
        <div className="blog-content-text-img">
          <div className="blog-content-text">
          <div className="blog-content-img cardImageDisplayTop">
            <img src={data.image}/>
          </div>
            <h2 className="blog-content-text-heading">
              {data.heading}
            </h2>
            <p className="blog-content-text-desc">
              {data.description}
            </p>
          </div>
          <div className="blog-content-img cardImageDisplayBottom">
            <img src={data.image}/>
          </div>
        </div>
        <div className="blog-content-date-readTime">
          <p className="blog-content-date">{data.date}</p>
          <p className="blog-content-readTime">{data.timeToRead}</p>
        </div>
      </div>
    </div>
  );
}

export default Blog;
