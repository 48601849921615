import { BrowserRouter,Routes,Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import NavBar from './Components/NavBar/NavBar';
import Contact from './Components/Contact/Contact';
import About from './Components/AboutUs/About';
import AllTataEvCars from './Components/AutoMobileBlogs/Tata/AllTataEvCars';
import Footer from './Components/Footer/Footer'
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermOfService from './Components/TermOfService/TermOfService';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

function App() {
  const TRACKING_ID = "G-7ZDGD0JG31"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
    <BrowserRouter>
    <NavBar/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/blogs' element={<Home/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/privacyPolicy' element={<PrivacyPolicy/>}/>
      <Route path='/termOfService' element={<TermOfService/>}/>

      <Route path='/allTataEvCars' element={<AllTataEvCars/>}/>




    </Routes>
    <Footer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
