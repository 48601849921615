import React from "react";
import "./Home.css";
import Blog from "../Blog/Blog";
import { Link } from "react-router-dom";
import img1 from '../../Images/blogPost01.png'
import img2 from '../../Images/blogPost02.webp'
import tataAvinya from '../../Images/Tata/avinya.jpg'



function Home() {
  // let blogCard1 = {
  //   heading: "Next.js Full-Stack Web App (2024)",
  //   description:
  //     " Hey guys 😃, are you planning to build a full-stack web application? If so, you’ve landed on the perfect blog post! I’m happy to introduce you to a Next.js full-stack starter template that I have designed to streamline the process of creating a modern, feature-rich web application and eliminate the…",
  //   timeToRead: "6 min read",
  //   image: img1,
  //   date: "Mar 25, 2024",
  // };
  // let blogCard2 = {
  //   heading: "Building RESTful APIs in Java: A Step-by-Step Tutorial",
  //   description: "Introduction: I am delighted to share my knowledge on building RESTful APIs using Java. RESTful APIs have become a fundamental part of modern web development, allowing applications to communicate and share data seamlessly. In this tutorial, we will create a simple yet powerful RESTful API using Java, Spring Boot, and Maven…",
  //   timeToRead: "5 min read",
  //   image: img2 ,
  //   date: "Mar 27, 2024",
  // };  
  let blogCard3 = {
    heading: "All Tata Ev Cars",
    description: "Explore Tata Motors' range of electric vehicles, including the efficient Nexon EV and versatile Tigor EV. Experience cutting-edge technology, eco-friendly performance, and modern design. Drive into the future with Tata's innovative electric mobility solutions.",
    timeToRead: "5 min read",
    image: tataAvinya ,
    date: "Mar 27, 2024",
  };

  return (
    <div className="home-main">
      <div className="home-content">
        {/* <Link to="/blogPost">
          <Blog data={blogCard1} />
        </Link>
        <Link to="/blogPost02">
          <Blog data={blogCard2} />
        </Link> */}
        <Link to="/allTataEvCars">
          <Blog data={blogCard3} />
        </Link>
      </div>
    </div>
  );
}

export default Home;
