import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="aboutMain">
        <h3>
          Welcome To <span id="W_Name1">bhandhara</span>
        </h3>
        <p className="mainParagraph">
          <span id="W_Name2">Bhandhara</span> is a Professional{" "}
          <span id="W_Type1">informational</span> Platform. Here we will provide
          you only interesting content, which you will like very much. We're
          dedicated to providing you the best of{" "}
          <span id="W_Type2">informational</span>, with a focus on dependability
          and <span id="W_Spec">provide information </span>. We're working to
          turn our passion for <span id="W_Type3">informational</span> into a
          booming online website . We hope you enjoy our{" "}
          <span id="W_Type4">informational</span> as much as we enjoy offering
          them to you.
        </p>
        <p className="mainParagraph">
          I will keep posting more important posts on my Website for all of you.
          Please give your support and love.
        </p>
        <p className="thanksP">Thanks For Visiting Our Site</p>
        <span className="niceDay">Have a nice day!</span>
      </div>
    </div>
  );
}

export default About;
